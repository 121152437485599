

.Header {
 height:60px;
 background:#FFFFFF;
 width:100vw;
 z-index: 10; 
 display:flex;
 align-items: center;
}

html{
  font-family: 'helvetica_mediumregular';
  -webkit-text-size-adjust: none; 
  text-size-adjust: none;
}

.title{
  font-weight:400;
  font-size:45px;
  line-height:45px;
  margin-left:14px;
}

.outer-wrapper{
  width:100vw;
  height:100vh;
  overflow-y: hidden;
  overflow-x: scroll;
}

.wrapper{
    /*width:400vw;*/
}

.background{
  background:url("chasstube.jpeg") no-repeat center center;
  background-size: 400vw;
  height:100vh;
  /*width:400vw;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.subtitle {
  font-family: 'gt_sectra';
  margin-left:5px;
  font-style:italic;
}
.citation-container{
  height:520px;
  margin-left:14px;
  margin-right:15px;
  margin-top:20px;
  position:relative;
}
.citation{
  font-family: 'helvetica_mediumregular';
font-weight: 400;
font-size: 45px;
line-height: 45px;
/* or 100% */


text-transform: uppercase;

color: #000000;
}

.author{
  position:absolute;
  bottom:20px;
  font-family: 'gt_sectra';
  font-style:italic;
  font-size: 40px;
  width:100%;
  text-align:right;
}

.fondue_citation_text{
  font-family: 'helvetica_mediumregular';
  font-size:20px;
}

.fondue_citation{
  border-bottom: 2px solid #000000;
}

.fondue_citation_author{
  text-align: right;
  margin-top:45px;
  font-style:italic;
  font-family: 'gt_sectra';
  font-size:25px;

}

#gabel_link{top: 30%; left: 10%}
#schale_link{top: 15%;left: 45%}
#caquelon_link{top: 40%; left: 80%}
#schalelang_link{top: 60%; left: 145%}
#teller_link{ top: 30%; left: 175% }
#schaleklein_link{top: 60%; left: 210%}

@media only screen and (orientation : landscape) {
  #gabel{width:auto;height:30%}
  #gabel_link{left:5%;top:45%}
  #schale_link{top: 9%;left: 15%}
  #schale{width:70%;height:auto}
  #caquelon_link{left:35%;top:40%}
  #caquelon{width:98%;height:auto}
  #schalelang_link{top: 70%; left: 65%}
  #teller_link{ top: 10%; left: 60% }
  #schaleklein_link{top: 38%; left: 80%}  
  #background_img{max-width: 100%;}
.img {max-height:150px;max-width:350px}
} 

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
  #gabel{width:auto;height:30%}
  #gabel_link{left:5%;top:45%}
  #schale_link{top: 9%;left: 15%}
  #schale{width:70%;height:auto}
  #caquelon_link{left:35%;top:40%}
  #caquelon{width:93%;height:auto}
  #schalelang_link{top: 70%; left: 65%}
  #teller_link{ top: 15%; left: 60% }
  #schaleklein_link{top: 44%; left: 80%}  
  #background_img{max-width: 100%;}
.img {max-height:200px;max-width:350px}

}

@media only screen 
and (min-device-width : 1025px) 
and (orientation : landscape) {
  #gabel{width:auto;height:30%}
  #gabel_link{left:5%;top:45%}
  #schale_link{top: 9%;left: 15%}
  #schale{width:70%;height:auto}
  #caquelon_link{left:35%;top:40%}
  #caquelon{width:93%;height:auto}
  #schalelang_link{top: 70%; left: 65%}
  #teller_link{ top: 15%; left: 60% }
  #schaleklein_link{top: 44%; left: 80%}  
  #background_img{max-width: 100%;}
.img {max-height:300px;max-width:350px}

}




@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
 #gabel_link{top: 30%; left: 10%}
 #schale_link{top: 7%;left: 34%}
 #caquelon_link{top: 29%; left: 80%}
 #schalelang_link{top: 60%; left: 145%}
 #teller_link{ top: 15%; left: 175% }
 #schaleklein_link{top: 44%; left: 210%}
.img {max-height:30%}

}

@media only screen 
and (max-device-width : 850px) 
and (orientation : portrait) {
 #gabel_link{top: 30%; left: 10%}
 #schale_link{top: 7%;left: 34%}
 #caquelon_link{top: 35%; left: 80%}
 #schalelang_link{top: 60%; left: 145%}
 #teller_link{ top: 15%; left: 175% }
 #schaleklein_link{top: 44%; left: 210%}
 #background_img{max-width: 250%;}
.img {max-height:30%}

}

:any-link {
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}



.sectra{
  font-family: 'gt_sectra';
  font-style:italic;
}
